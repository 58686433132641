<template>
  <svg
    class="map country africa"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 387.3 463.5"
  >
    <g class="hover">
      <path
        d="M233.9,310l.6,2-.7,3.1.9,3-.9,2.4.4,2.2-11.7-.1-.8,20.5,3.6,5.2,3.6,4-10.4,2.6L205,354l-3.8-3-22.7.2-.8.5-3.3-2.9-3.6-.2-3.4,1.1-2.7,1.2-.5-4,.9-5.7,2-5.9.3-2.7,1.9-5.8,1.4-2.6L174,320l1.9-2.9.6-4.7-.3-3.7-1.6-2.3-1.5-3.9-1.3-3.8.3-1.4,1.7-2.5-1.6-6.2-1.2-4.3-2.8-4.1.6-1.2,2.3-.9,1.7.1,2-.7,16.7.1,1.3,4.7,1.6,3.9,1.3,2.1,2.1,3.3,3.8-.5,1.8-.9,3.1.9.9-1.6,1.5-3.7,3.5-.3.3-1.1h2.9l-.5,2.3,6.8-.1.1,4.1,1.1,2.4-.9,3.9.4,4,1.8,2.4L224,311l1.4-.6,2.4.2,3.5-1Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M168,277l-1.5-4.8,2.3-2.8,1.7-1.1,2.1,2.2-2,1.4-1,1.6-.2,2.8Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M267.6,268.4l-.6.1v-.3l-2-6.1V262l-.09-1-1.4-2.9,3.5.5,1.7-3.7,3.1.4.3,2.5,1.2,1.5v2.1l-1.4,1.3-2.3,3.4-2,2.3Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M119.4,165l-.2,2.1,1.3,3.8-1.1,2.6.6,1.7-2.8,4-1.7,2-1.1,4,.2,4.1-.3,10.3-4.7.8-1.4-4.4.3-14.8-1.2-1.3-.2-3.2-2-2.2-1.7-1.9.7-3.4,2-.7,1.1-2.8,2.8-.6,1.2-1.9,1.9-1.9h2l4.3,3.7Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M101.2,144l-.5,3.1.8,2.9,3.1,4.2.2,3.1,6.5,1.5-.1,4.4-1.2,1.9-2.8.6-1.1,2.8-2,.7-4.9-.1-2.6-.5-1.8,1-2.5-.5-9.8.3-.2,3.7.8,4.8-3.9-1.6-2.6.2-2,1.6-2.5-1.3-1-2.2-2.5-1.4-.4-3.7,1.6-2.7-.2-2.2,4.5-5.3.9-4.4,1.5-1.6,2.7.9,2.4-1.3.8-1.7,4.3-2.8,1.1-2,5.3-2.7,3.1-.9,1.4,1.2Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M240.3,353.7l1.9,5.1,1.1,1.2,1.6,3.7,6.1,7,2.3.7-.1,2.3,1.5,4.1,4.3,1,3.4,2.9-8.1,4.7-5.2,4.8-2,4.3-1.8,2.4-3,.5-1.2,3.1-.6,2-3.6,1.4-4.5-.3-2.5-1.8-2.3-.7-2.8,1.4-1.5,3.1-2.7,1.9-2.8,2.9-4,.7-1.1-2.3.6-3.9-3-6.1-1.4-1,.6-18.7,5.5-.2.8-22.9,4.2-.2,8.7-2.3,2,2.7,3.7-2.5h1.7l3.2-1.5,1,.5Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M234,184.5l3.9,2.5,3.1,2.6.1,2.1L245,195l2.4,2.8,1.4,3.8,4.3,2.6.9,2-1.8.7-3.7-.1-4.2-.7-2.1.5-.9,1.6-1.8.2-2.2-1.4-6.3,3.2-2.6-.6-.8.5L226,214l-4.3-1.3-4.1-.6-3.6-2.4-4.7-2.2-3,2.1-2.2,3.2-.5,4.5L200,217l-3.9-1.1-3.3,3.4-3,6-.6-1.9-.3-2.9-2.6-2.1-2.1-3.3-.5-2.3-2.7-3.4.5-1.9-.6-2.7.4-5,1.4-1.1,2.8-6.5,4.6-.5,1-1.7,1,.2,1.4,1.4,7.1-2.4,2.4-2.5,2.9-2.3-.6-2.2,1.6-.6,5.5.4,5.2-3,4-7,2.8-2.6,3.6-1.1.7,2.7,3.3,4v2.7l-.8,2.6.4,2,1.9,1.9.5.3Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M68.6,173.2l2.5,1.4,1,2.2,2.5,1.3,2-1.6,2.6-.2,3.9,1.6,1.5,9.2-2.4,5.3-1.5,7.3,2.4,5.5-.2,2.6H80.3l-3.9-1.2H72.7L66,207.8l-3.9,1.8L56.5,212l-1.1-.2.4-5.3.6-.8-.2-2.5-2.4-2.7-1.8-.4-1.6-1.8,1.2-2.9-.5-3.1.2-1.8h.9l.4-2.8-.4-1.3.5-.9,2.1-.7-1.4-5.2L52.1,177l.5-2.2,1.1-.5.8-.6,1.5,1h4.4l1-1.8,1,.1,1.6-.7.9,2.7,1.3-.8Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M185.5,192.2l-2.8,6.5-1.4,1.1-.4,5,.6,2.7-.5,1.9,2.7,3.4.5,2.3,2.1,3.3,2.6,2.1.3,2.9.6,1.9-.4,3.4-4.5-1.5-4.6-1.7-7.1-.2-.7-.4-3.4.8-3.4-.8-2.7.4-9.3-.1.9-5.1-2.3-4.3-2.6-1-1.1-2.9-1.5-.9.1-1.8,1.4-4.6,2.7-6.2h1.6l3.4-3.8,2.1-.1,3.2,2.7,3.9-2.2.5-2.7,1.3-2.6.8-3.2,3-2.6,1.1-4.5,1.2-1.5.8-3.3,1.4-4.1,4.7-5,.3-2.1.6-1.2-2.3-2.5.2-2.1,1.5-.3,2.3,4.1.5,4.2-.2,4.3,3.2,5.8h-3.2l-1.6.4-2.6-.6-1.2,3,3.4,3.8,2.5,1.1.8,2.6,1.8,4.4-.8,1.8Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M254,206.2l3.5,5.3,2.6.8,1.5-1.1,2.6.4,3.1-1.3,1.4,2.7,5.1,4.3-.3,7.5,2.3.9-1.9,2.2-2.1,1.8-2.2,3.3-1.2,3-.3,5.1-1.3,2.5-.1,4.8-1.6,1.8-.2,3.8-.8.5-.6,3.6,1.4,2.9.1,1-1.2,10.3,1.5,3.6-1,2.7,1.8,4.6,3.4,3.5.7,3.5,1.6,1.7-.3,1.1-.9-.3-7.7,1.1-1.5.8-1.7,4.1,1.2,2.8-1.1,7.6-.9,6.4,1.5,1.2,3.9,2.5,1.6-1.2.2,6.9h-4.3l-2.2-3.5-2-2.8-4.3-.9-1.2-3.3-3.5,2-4.4-.9-1.9-2.9-3.5-.6-2.7.1-.3-2-1.9-.1-2.6-.4-3.5,1-2.4-.2-1.4.6.4-7.6-1.8-2.4-.4-4,.9-3.9-1.1-2.4-.1-4.1-6.8.1.5-2.3h-2.9l-.3,1.1-3.5.3-1.5,3.7-.9,1.6-3.1-.9-1.8.9-3.8.5-2.1-3.3-1.3-2.1-1.6-3.9-1.3-4.7-16.7-.1-2,.7-1.7-.1-2.3.9-.8-2,1.4-.7.2-2.8,1-1.6,2-1.4,1.5.7,2-2.5,3.1.1.3,1.8,2.1,1.1,3.4-4,3.3-3.1,1.4-2.1-.1-5.3,2.5-6.2,2.6-3.3,3.7-3.1.7-2,.1-2.4.9-2.2-.3-3.7.7-5.7,1.1-4,1.7-3.4.3-3.9.5-4.5,2.2-3.2,3-2.1,4.7,2.2,3.6,2.4,4.1.6L226,214l1.6-3.9.8-.5,2.6.6,6.3-3.2,2.2,1.4,1.8-.2.9-1.6,2.1-.5,4.2.7,3.7.1,1.8-.7Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M203.6,217.3l-.3,3.9-1.7,3.4-1.1,4-.7,5.7.3,3.7-.9,2.2-.1,2.4-.7,2-3.7,3.1-2.6,3.3-2.5,6.2.1,5.3-1.4,2.1-3.3,3.1-3.4,4-2.1-1.1-.3-1.8-3.1-.1-2,2.5-1.5-.7-2.1-2.2-1.7,1.1-2.3,2.8-4.6-6.8,4.3-3.6-2.1-4.2,2-1.6,3.8-.8.4-2.9,3.1,3.1,5,.3,1.7-3,.7-4.3-.6-5-2.7-3.8,2.5-7.5-1.4-1.2-4.2.5-1.6-3.3.4-2.8,7.1.2,4.6,1.7,4.5,1.5.4-3.4,3-6,3.3-3.4L200,217l3.6.3Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M342.2,166.2l-1.9,3.5-1.3-1.2-1.3.5-3.2-.1-.2-2-.5-1.8,1.8-3,1.9-2.8,2.4.6,1.7-1.6,1.4,2-.1,2.6-3.1,1.6,2.4,1.7Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M143.7,2.6l-1,3.3,1,6.1-1.1,5.3-3.2,3.6.6,4.8,4.5,3.9.1,1.5,3.4,2.6,2.6,11.5,1.9,5.7.4,3-.8,5.2.4,3-.6,3.5.6,4-2.2,2.7,3.4,4.7.2,2.7,2.1,3.6,2.5-1.2,4.5,3,2.5,4-18.8,12.3-16,12.6-7.8,2.8-6.2.7-.1-4.1-2.6-1.1-3.5-1.8-1.3-3-18.7-14-18.6-14L51.4,63.9l.1-1.2.1-.4.1-7.6,8.9-4.8,5.4-1,4.5-1.7L72.6,44,79,41.5l.3-4.8,3.1-.6,2.5-2.3L92,32.7l1-2.5-1.4-1.4L89.7,22l-.3-3.9L87.5,14l5.1-3.5,5.8-1.1,3.3-2.6,5.1-2,9-1.1,8.8-.5,2.7.9,4.9-2.5,5.7-.1L140.1,3l3.6-.4Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M284.8,39.4l3.9,9.4.7,1.6L288.1,53l-.7,4.8-1.2,3.4L285,62.3l-2-2.1-2.7-2.8-4.7-9.2-.5.6,2.8,6.7,3.9,6.5,4.9,10,2.3,3.5,2,3.6,5.4,7.1-1,1.1.4,4.2,6.8,5.8,1.1,1.3H237.8l-1-23.7-1.3-22.8-2-5.2,1.1-3.9-1-2.8,1.7-3.1,7.2-.1,5.4,1.7,5.5,1.9,2.6,1,4-2,2.1-1.8,4.7-.6,3.9.8,1.8,3.2,1.1-2.1,4.4,1.5,4.3.4,2.5-1.6Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M341.6,158.3l-1.7,1.6-2.4-.6-2-2.1-2.5-3.7-2.6-2.1-1.5-2.2-5-2.6-3.9-.1-1.4-1.3-3.2,1.5-3.6-2.9-1.5,4.8-6.6-1.4-.7-2.5,2-9.5.3-4.2,1.7-2,4-1.1,2.7-3.6,3.6,7.4,1.9,5.9,3.2,3.1,8,6.1,3.3,3.6,3.2,3.8,1.8,2.2,2.9,1.9Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        :class="`hover ethopia ${isActive('Ethiopia')}`"
        data-loc="308,150"
        @click="quickViewShop('ethiopia')"
        d="M320,146.5l3.9.1,5,2.6,1.5,2.2,2.6,2.1,2.5,3.7,2,2.1-1.9,2.8-1.8,3,.5,1.8.2,2,3.2.1,1.3-.5,1.3,1.2-1.2,2.2,2.2,3.6,2.2,3.1,2.2,2.3,18.7,7.6,4.8-.1-15.6,19.3-7.3.3-5,4.5-3.6.1-1.5,2.1h-3.9l-2.3-2.2-5.2,2.7-1.6,2.7-3.8-.6-1.3-.7-1.3.2-1.8-.1-7.2-5.4h-4l-1.9-2.1-.1-3.6-2.9-1.1-3.5-7-2.6-1.5-1-2.6-3-3.1-3.5-.5,1.9-3.6,3-.2.8-1.9-.2-5v-.8l1.5-6.7,2.6-1.8.5-2.6,2.3-5,3.3-3.1,2-6.4.7-5.5,6.6,1.4,1.5-4.8,3.6,2.9,3.2-1.5,1.4,1.3Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M173.2,225.3l-.4,2.8,1.6,3.3,4.2-.5,1.4,1.2-2.5,7.5,2.7,3.8.6,5-.7,4.3-1.7,3-5-.3-3.1-3.1-.4,2.9-3.8.8-2,1.6,2.1,4.2-4.3,3.6-5.8-6.5-3.7-5.3-3.5-6.6.2-2.2,1.3-2,1.3-4.7,1.2-4.8,1.9-.3H163v-7.7l2.7-.4,3.4.8,3.4-.8.7.4Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M99.2,169.1l-.4,2,2.3,3.3v4.7l.6,5,1.4,2.4-1.3,5.7.5,3.2,1.5,4.1,1.3,2.3-8.9,3.7L93,207.7l-5.1,1.9-5-1.8.2-2.6-2.4-5.5,1.5-7.3,2.4-5.3-1.5-9.2-.8-4.8.2-3.7,9.8-.3,2.5.5,1.8-1,2.6.5Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M34.2,159.9l.3,2.4h.9l1.5-.9.9.2,1.6,1.7,2.4.5,1.5-1.4,1.9-.9,1.3-.9,1.1.2,1.3,1.4.6,1.8,2.3,2.7-1.1,1.6-.3,2.1,1.2-.6.7.7-.3,1.9,1.7,1.9-1.1.5-.5,2.2,1.3,2.6,1.4,5.2-2.1.7-.5.9.4,1.3-.4,2.8h-.9l-1.6-.2-1.1,2.6H47l-1.1-1.4.4-2.6L43.9,185l-1.4.7-1.3.2-1.5.3.1-2.3-.9-1.7.2-1.9-1.2-2.7-1.6-2.3H31.8l-1.3,1.2-1.6.2-1,1.4-.6,1.7-3.1,2.9-2.4-3.8-2.2-2.5-1.4-.9-1.4-1.3-.6-2.8-.8-1.4-1.7-1.1,2.6-3.1,1.7.1,1.5-1,1.2-.1.9-.8-.4-2.1.6-.7.1-2.2,2.7.1,4.1,1.5,1.2-.1.4-.7,3.1.5.8-.4Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M4.3,155.4l.8-2.9,6.1-.1,1.3-1.6,1.8-.1,2.2,1.6h1.7l1.9-1,1.1,1.8-2.5,1.5-2.4-.2-2.4-1.3-2.1,1.5h-1l-1.4.9-5.1-.1Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M21.9,159l-.1,2.2-.6.7.4,2.1-.9.8-1.2.1-1.5,1-1.7-.1-2.6,3.1-2.9-2.6-2.4-.5L7.1,164l.1-1-1.7-1.3-.4-1.4,3-1,1.9.2,1.5-.8,10.4.3Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M163,225.3V233h-8.2l-1.9.3-1.1-.9,1.9-7.2,9.3.1Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M336.2,214.7l-4.9,7.2.2,23.4,3.3,5.3-4,2.6-1.4,2.7-2.2.4-.8,4.6-1.9,2.6-1.1,4.2-2.3,2.1-8.1-6.4-.3-3.7-20.5-13.1.4-4.7-1.4-2.5v-.3l1.6-2.6,2.8-4.2,2.1-4.7-2.6-7.4-.7-3.2-2.7-4.5,3.4-3.8,3.8-4.2,2.9,1.1.1,3.6,1.9,2.1h4l7.2,5.4,1.8.1,1.3-.2,1.3.7,3.8.6,1.6-2.7,5.2-2.7,2.3,2.2Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M51.3,190.5l-.2,1.8.5,3.1-1.2,2.9,1.6,1.8,1.8.4,2.4,2.7.2,2.5-.6.8-.4,5.3-1.5.1-5.8-3.1-5.2-4.9-4.8-3.5-3.8-4.1,1.4-2.1.3-1.9,2.6-3.4,2.6-3,1.3-.2,1.4-.7,2.4,3.9-.4,2.6,1.1,1.4h1.6l1.1-2.6,1.6.2Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M235.3,37.1l-1.7,3.1,1,2.8-1.1,3.9,2,5.2,1.3,22.8,1,23.7.5,12.8h-6.4v2.7l-22.6-12.3L186.8,89.5,181.3,93l-3.8,2.4-3.2-3.5-8.8-2.8-2.5-4-4.5-3L156,83.3l-2.1-3.6-.2-2.7-3.4-4.7,2.2-2.7-.6-4,.6-3.5-.4-3,.8-5.2-.4-3-1.9-5.7,2.6-1.4.4-2.8-.6-2.6,3.6-2.5,1.6-2.1,2.6-1.8.1-4.9,6.4,2.2,2.3-.6,4.5,1.1,7.3,2.9,2.8,5.7,4.9,1.2,7.8,2.7,6,3.2,2.5-1.7,2.5-2.9L206.3,36l1.5-3.2,3.7-3,3.7-.8,7.4,1.3,2,2.8,2,.1,1.8,1.1,5.4.7,1.5,2.1Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M251.8,435.9l-2,.7-3.7-5,3.2-4,3.1-2.5,2.7-1.4,2.2,2,1.7,2-1.9,3.1-1.1,2.1-3.1,1-1.1,2Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M87.5,14l1.9,4.1.3,3.9,1.9,6.8L93,30.2l-1,2.5-7.1,1.1-2.5,2.3-3.1.6L79,41.5,72.6,44l-2.1,3.2L66,48.9l-5.4,1-8.9,4.8-.1,7.6h-.9l.1,3.4-3.4.2-1.8,1.5H43.1l-2-.9-4.6.7-1.9,5-1.8.5-2.7,8.1-7.9,6.9-2,8.9-2.4,2.9-.7,2.3-12.5.5H4.5l.3-3L7,97.6l1.9-3.4L8.6,92l2-4.5,3.2-4.1,1.9-1,1.6-3.7.2-3.5,2.1-3.9,3.8-2.4L27,62.4l.1-.1L30,59.8l5.1-.7,4.4-4.4L42.3,53,47,47.6l-1.2-7.9L48,34.1l.9-3.4,3.6-4.3,5.4-2.9L62,20.8l3.7-6.6,1.8-4,3.9.1L74.5,13l5.1-.4L85.1,14Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M122.9,116.8l.1,14.8-3.1,4.3-.4,4-5,1-7.7.5-2,2.3-3.6.3H97.6l-1.4-1.2-3.1.9-5.3,2.7-1.1,2-4.3,2.8-.8,1.7-2.4,1.3-2.7-.9L75,154.9l-.9,4.4-4.5,5.3.2,2.2-1.6,2.7.4,3.7-2.4,1-1.3.8-.9-2.7-1.6.7-1-.1-1,1.8H56l-1.5-1-.8.6L52,172.4l.3-1.9-.7-.7-1.2.6.3-2.1,1.1-1.6L49.5,164l-.6-1.8-1.3-1.4-1.1-.2-1.3.9-1.9.9-1.5,1.4-2.4-.5-1.6-1.7-.9-.2-1.5.9h-.9l-.3-2.4.3-2-.5-2.4-2-1.8L30.9,150l-.2-4,1.9-1.2,1-3.8,1.8-.1,3.9,1.8,3.2-1.3,2.1.4.9-1.4,22.5-.1,1.3-4.5-1-.8-2.5-27.7L63.4,79.6l8.5-.1,18.6,14,18.7,14,1.3,3,3.5,1.8,2.6,1.1.1,4.1,6.2-.7Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M279.4,411.5h-4.1l-.3-2.9-.6-2.9-.4-2.3,1.4-7.1-1.1-4.6-2.2-9,6.2-7.3,1.7-4.6.8-.6.9-3.8-.8-1.9.4-4.8,1.3-4.4.4-8.2-2.8-2-2.7-.5-1.1-1.6-2.6-1.3-4.7.1-.2-2.4-.4-4.6,17.2-5.3,3.2,3.1,1.5-.6,2.2,1.6.2,2.6-1.3,3,.2,4.5,3.5,4,1.9-4.5,2.5-1.3-.1-8.3-2.2-4.6-1.9-2.1H295l-.6-7.3,1.5-6.1,2.2-.2,6.7,1.8,1.5-.8,3.9-.2,2.1-1.9,3.4.1,6.2-2.5,4.6-3.7.9,2.8-.5,6.4.5,5.7-.2,10,.8,3.1-1.9,4.6-2.4,4.5-3.7,4-5.3,2.4-6.5,3.1-6.6,6.9-2.2,1.2-4.2,4.6-2.3,1.4-.8,4.6,2.4,4.9.9,3.7v2l1-.4-.5,6.3-1.1,3L296,392l-1,2.7-2.4,2.3-4.7,2.1-6.9,3.5-2.5,2.4.3,2.7,1.3.4-.7,3.4Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M71.9,79.5l-8.5.1,2.4,27.7L68.3,135l1,.8L68,140.3l-22.5.1-.9,1.4-2.1-.4-3.2,1.3-3.9-1.8-1.8.1-1,3.8L30.7,146l-3.6-4.4-3.4-4.8-3.6-1.7-2.7-1.8H14.3l-2.8,1.4-2.7-.5-2,2-.4-3.4L8,129.6l.8-6-.4-6.4L7.8,114l.6-3.2-1.4-3L4.2,105l1.3-2.1H27.2l-.9-9.3,1.5-3.3,5.2-.5.2-16.5,18,.4.2-9.8L71.9,79.5Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M295,326.9h.4l1.9,2.1,2.2,4.6.1,8.3-2.5,1.3-1.9,4.5-3.5-4-.2-4.5,1.3-3-.2-2.6-2.2-1.6-1.5.6-3.2-3.1-2.9-1.6,2-6,1.8-2.2-.9-5.4,1.3-5.2,1-1.7-1.3-5.4-2.6-2.9,5.5,1.2,1,1.7-.1.8,1.8,4.1.2,7.7-1.8,3.6,1.6,4.7-.2,2.8,1.2,1.9-.1,2.4.9,1.4,1-1.6,1.9,2.5.2-.8-1-3.4-1.1-.3-.1-.9Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M228.9,352.3l4.6-1.4,3.6.3,2.2,1.5v.5l-3.2,1.5h-1.7l-3.7,2.5-2-2.7-8.7,2.3-4.2.2-.8,22.9-5.5.2-.6,18.7-1.1,23.7-5,3.3-2.9.5-3.4-1.2-2.5-.5-.8-2.7-2-1.8-2.8,3.2-3.9-4.9-2-4.6-1-6.3-1.2-4.6-1.6-9.9.1-7.7-.6-3.5-2.1-2.7-2.8-5.3-2.8-7.7-1.1-4-4.4-6.3-.3-4.9,2.7-1.2,3.4-1.1,3.6.2,3.3,2.9.8-.5,22.7-.2,3.8,3,13.5.9,10.4-2.6Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M181.3,93l1.6,10,2.2,1.7.1,2,2.4,2.2-1.2,2.8-1.8,13-.2,8.4-7,6-2.3,8.5,2.4,2.4v4.1l3.7.1-.6,3.1-1.5.3-.2,2.1-1,.1-3.9-7-1.4-.3-4.3,3.6-4.4-1.9-3-.3-1.6.9-3.3-.2-3.3,2.7-2.9.2-6.8-3.3-2.7,1.5-2.9-.1-2.1-2.4-5.6-2.4-6.1.8-1.4,1.3-.8,3.7-1.6,2.6-.4,5.8-4.3-3.7h-2l-1.9,1.9.1-4.4-6.5-1.5-.2-3.1-3.1-4.2-.8-2.9.5-3.1,3.6-.3,2-2.3,7.7-.5,5-1,.4-4,3.1-4.3-.1-14.8,7.8-2.8,16-12.6,18.8-12.3,8.8,2.8,3.2,3.5,3.8-2.4Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M178.9,159.7l2.3,2.5-.6,1.2-.3,2.1-4.7,5-1.4,4.1-.8,3.3-1.2,1.5-1.1,4.5-3,2.6-.8,3.2-1.3,2.6-.5,2.7-3.9,2.2-3.2-2.7-2.1.1-3.4,3.8h-1.6l-2.7,6.2-1.4,4.6-5.9,2.3-2.1-.3-2.2,1.4-4.5-.1-3.1-4.1-1.9-4.6-4-4.2h-9.2l.3-10.3-.2-4.1,1.1-4,1.7-2,2.8-4-.6-1.7,1.1-2.6-1.3-3.8.2-2.1.4-5.8,1.6-2.6.8-3.7,1.4-1.3,6.1-.8,5.6,2.4,2.1,2.4,2.9.1,2.7-1.5,6.8,3.3,2.9-.2,3.3-2.7,3.3.2,1.6-.9,3,.3,4.4,1.9,4.3-3.6,1.4.3,3.9,7,1-.1Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        :class="`hover rwanda ${isActive('Rwanda')}`"
        data-loc="254,208"
        @click="quickViewShop('rwanda')"
        d="M271.5,247.1l2.2,3.6-.3,3.8-1.6.8-3.1-.4-1.7,3.7-3.5-.5.6-3.6.8-.5.2-3.8,1.6-1.8,1.4.7,3.4-2Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M51.6,62.3l-.1.4-.1,1.2-.2,9.8-18-.4L33,89.8l-5.2.5-1.5,3.3.9,9.3H5.5L4.2,105l.3-2.7h.1l12.5-.5.7-2.3,2.4-2.9,2-8.9,7.9-6.9,2.7-8.1,1.8-.5,1.9-5,4.6-.7,2,.9h2.5l1.8-1.5,3.4-.2-.1-3.4Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M303.7,147.2l-.7,5.5-2,6.4-3.3,3.1-2.3,5-.5,2.6-2.6,1.8-1.5,6.7v.8l-.8-.2.1-3.2-.8-2.2-2.9-2.5-.9-4.6.6-4.8-2.6-.4-.4,1.4-3.4.4,1.5,1.8.5,3.9-3,3.5-2.7,4.6-2.9.7-4.8-3.7-2.1,1.3-.5,1.8-2.9,1.3-.2,1.3H257l-.8-1.3-4.1-.3-2,1.1-1.6-.5-2.9-3.8-1-1.7-4.1.9-1.5,2.9-1.3,5.8-2,1.2-1.7.7-.5-.3-1.9-1.9-.4-2,.8-2.6V175l-3.3-4-.7-2.7v-1.6l-2.1-1.9-.1-3.7-1.3-2.5-1.9.4.5-2.4,1.4-2.6-.7-2.7,1.8-2-1.2-1.5,1.3-3.9,2.5-4.8,4.8.5-1.1-25.5v-2.7h6.4l-.5-12.8h65.9l2.1,6.3-1.2,1.1,1.2,6.7,2.5,7.6,2.2,1.6,3.2,2.4-2.7,3.6-4,1.1-1.7,2-.3,4.2-2,9.5.7,2.5Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M290.8,179.1l.2,5-.8,1.9-3,.2-1.9,3.6,3.5.5,3,3.1,1,2.6,2.6,1.5,3.5,7-3.8,4.2-3.4,3.8-3.5,3h-4l-4.5,1.5-3.6-1.5-2.3,1.8-5.1-4.3-1.4-2.7-3.1,1.3-2.6-.4-1.5,1.1-2.6-.8-3.5-5.3-.9-2-4.3-2.6-1.4-3.8L245,195l-3.9-3.3-.1-2.1-3.1-2.6-3.9-2.5,1.7-.7,2-1.2,1.3-5.8,1.5-2.9,4.1-.9,1,1.7,2.9,3.8,1.6.5,2-1.1,4.1.3.8,1.3h5.6l.2-1.3,2.9-1.3.5-1.8,2.1-1.3,4.8,3.7,2.9-.7,2.7-4.6,3-3.5-.5-3.9-1.5-1.8,3.4-.4.4-1.4,2.6.4-.6,4.8.9,4.6,2.9,2.5.8,2.2-.1,3.2.8.2Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M30.7,146l.2,4,1.1,3.7,2,1.8.5,2.4-.3,2-.8.4-3.1-.5-.4.7-1.2.1-4.1-1.5-2.7-.1-10.4-.3-1.5.8-1.9-.2-3,1-.8-4.9,5.1.1,1.4-.9h1l2.1-1.5,2.4,1.3,2.4.2,2.5-1.5-1.1-1.8-1.9,1H16.5l-2.2-1.6-1.8.1-1.3,1.6-6.1.1-2.3-5L.1,145.3,2.6,144l2.8-4.5,1.4-3.3,2-2,2.7.5,2.8-1.4h3.1l2.7,1.8,3.6,1.7,3.4,4.8,3.6,4.4Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M41.2,185.9l-2.6,3L36,192.3l-.3,1.9-1.4,2.1-1.5-.5-4-2.6-3-3.4-.9-2.4-.7-4.7,3.1-2.9.6-1.7,1-1.4,1.6-.2,1.3-1.2h4.5l1.6,2.3,1.2,2.7-.2,1.9.9,1.7-.1,2.3,1.5-.3Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M274.4,405.7l.6,2.9.3,2.9-1.4,2.8-3.2.7-3.1-3.5.1-2.2,1.7-2.4.6-1.9,1.7-.4,2.7,1.1Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M231.9,114.1l1.1,25.5-4.8-.5-2.5,4.8-1.3,3.9,1.2,1.5-1.8,2,.7,2.7-1.4,2.6-.5,2.4,1.9-.4,1.3,2.5.1,3.7,2.1,1.9v1.6l-3.6,1.1-2.8,2.6-4,7-5.2,3-5.5-.4-1.6.6.6,2.2-2.9,2.3-2.4,2.5-7.1,2.4-1.4-1.4-1-.2-1,1.7-4.6.5.8-1.8-1.8-4.4-.8-2.6-2.5-1.1-3.4-3.8,1.2-3,2.6.6,1.6-.4h3.2l-3.2-5.8.2-4.3-.5-4.2-2.3-4.1.6-3.1-3.7-.1V150l-2.4-2.4,2.3-8.5,7-6,.2-8.4,1.8-13,1.2-2.8-2.4-2.2-.1-2-2.2-1.7-1.6-10,5.5-3.5,22.5,12.3,22.6,12.3Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M104.1,169.2l-.7,3.4,1.7,1.9,2,2.2.2,3.2,1.2,1.3-.3,14.8,1.4,4.4-4.5,1.4-1.3-2.3-1.5-4.1-.5-3.2,1.3-5.7-1.4-2.4-.6-5v-4.7l-2.3-3.3.4-2,4.9.1Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M160.9,27.1l-.1,4.9-2.6,1.8-1.6,2.1L153,38.4l.6,2.6-.4,2.8-2.6,1.4L148,33.7l-3.4-2.6-.1-1.5L140,25.7l-.6-4.8,3.2-3.6,1.1-5.3-1-6.1,1-3.3L149.4.1l3.7.7V4.1l4.4-2.4.4,1.2-2.5,3.2.1,2.9,1.9,1.6-.5,5.6-3.5,3.2,1.2,3.5,2.8.1,1.4,3.1,2.1,1Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        :class="`hover tanzania ${isActive('Tanzania')}`"
        data-loc="285,262"
        @click="quickViewShop('tanzania')"
        d="M279.7,246.4l-.2,3.9-1.1,4.5,1.6,2.5,2.5-1.5,3.3-.4.7.8,3.3-1.6-2.3-2.2,1.9-2.9,2.8-2.9,20.5,13.1.3,3.7,8.1,6.4-2.8,8,.3,3.6,3.5,2.3.2,1.7-1.7,3.9.3,1.9-.4,3.1,1.8,4,2.2,6.4,2,1.4-4.6,3.7-6.2,2.5-3.4-.1-2.1,1.9-3.9.2-1.5.8-6.7-1.8-2.2.2.1-.1-1.8-2.4-.3-6.8-2.9-3.4-.4,1.2-1-1.7-5.5-1.2-3.2-1.9-3.6-1.1-2.2-1.1-.3-.2-2.7-6.6-.4-3.9-4.5-4.4,1.4-2.4-1.1-2.6.2-2.7-1-.9.3-2.8.6-.1,2-2.3,2.3-3.4,1.4-1.3v-2.1l-1.2-1.5-.3-2.5,1.6-.8.3-3.8-2.2-3.6,2-.8,6.2.1Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M291.7,212.5l2.7,4.5.7,3.2,2.6,7.4-2.1,4.7-2.8,4.2-1.6,2.6v.3l-.2-.4-3-1.3-2.4,1.6-3.6.9-2.6,3.7.3,2.5-6.2-.1-2,.8-3.4,2-1.4-.7.1-4.8,1.3-2.5.3-5.1,1.2-3,2.2-3.3,2.1-1.8,1.9-2.2-2.3-.9.3-7.5,2.3-1.8,3.6,1.5,4.5-1.5h4l3.5-3Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M274.8,294.8l.3.2,2.2,1.1,3.6,1.1,3.2,1.9,2.6,2.9,1.3,5.4-1,1.7-1.3,5.2.9,5.4-1.8,2.2-2,6,2.9,1.6-17.2,5.3.4,4.6-4.3.9-3.3,2.5-.8,2.2-2,.6-5.1,5.2-3.2,4.2-1.9.1-1.8-.7-6.2-.7-1-.5v-.5l-2.2-1.5-3.6-.3-4.6,1.4-3.6-4-3.6-5.2.8-20.5,11.7.1-.4-2.2.9-2.4-.9-3,.7-3.1-.6-2,1.9.1.3,2,2.7-.1,3.5.6,1.9,2.9,4.4.9,3.5-2,1.2,3.3,4.3.9,2,2.8,2.2,3.5h4.3l-.2-6.9-1.6,1.2-3.9-2.5-1.5-1.2.9-6.4,1.1-7.6-1.2-2.8,1.7-4.1,1.5-.8,7.7-1.1.9.3-.3,1.4,1.9.5,1.2,1.3,1-.3-.5-1.1Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M272.1,382.7l-2.9-.7-1.9.8-2.7-1.1h-2.2l-3.4-2.9-4.3-1-1.5-4.1.1-2.3-2.3-.7-6.1-7-1.6-3.7-1.1-1.2-1.9-5.1,6.2.7,1.8.7,1.9-.1,3.2-4.2,5.1-5.2,2-.6.8-2.2,3.3-2.5,4.3-.9.2,2.4,4.7-.1,2.6,1.3,1.1,1.6,2.7.5,2.8,2-.4,8.2-1.3,4.4-.4,4.8.8,1.9-.9,3.8-.8.6-1.7,4.6-6.2,7.3Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M334.8,250.6l-3.3-5.3-.2-23.4,4.9-7.2,1.5-2.1,3.6-.1,5-4.5,7.3-.3,15.6-19.3-4.8.1-18.7-7.6-2.2-2.3-2.2-3.1-2.2-3.6,1.2-2.2,1.9-3.5,1.9,1.2,1.2,2.7,2.7,2.7h2.8l5.2-1.7,6.1-.7,4.9-2,2.8-.4,2-1.2,3.2-.2,1.8-.2,2.5-.9,3-.7,2.5-2.2H387l.2,1.8-.4,3.7.2,3.4-1.1,2.3-1.4,7-2.4,7.1-3.3,8.2-4.6,9.4-4.7,7.2-6.6,8.8-5.6,5.2-8.4,6.4-5.3,4.8-6.2,7.8-1.3,3.4-1.3,1.5Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
      <path
        d="M272.1,382.7l2.2,9,1.1,4.6-1.4,7.1.4,2.3-2.7-1.1-1.7.4-.6,1.9-1.7,2.4-.1,2.2,3.1,3.5,3.2-.7,1.4-2.8h4.1l-1.7,4.7-1,5.3-1.7,2.9-4,3.3-1.1.9-2.6,3.3-1.8,3.3-3.5,4.6-6.7,6.6-4.1,3.8-4.3,3-5.9,2.4-2.7.4-.9,1.8-3.2-1-2.7,1.2-5.7-1.2-3.3.8-2.2-.4-5.8,2.6-4.6,1-3.5,2.4-2.4.2-2.1-2.3-1.8-.1-2.2-2.9-.3.9-.6-1.7.3-3.8-1.5-4.3,1.8-1.2.1-4.9-3.3-6-2.4-5.4v-.1l-3.6-8.3,2.8-3.2,2,1.8.8,2.7,2.5.5,3.4,1.2,2.9-.5,5-3.3,1.1-23.7,1.4,1,3,6.1-.6,3.9,1.1,2.3,4-.7,2.8-2.9,2.7-1.9,1.5-3.1,2.8-1.4,2.3.7,2.5,1.8,4.5.3,3.6-1.4.6-2,1.2-3.1,3-.5,1.8-2.4,2-4.3,5.2-4.8,8.1-4.7h2.2l2.7,1.1,1.9-.8,2.9.7Zm-20.3,53.2,1.1-2,3.1-1,1.1-2.1,1.9-3.1-1.7-2-2.2-2-2.7,1.4-3.1,2.5-3.2,4,3.7,5,2-.7Z"
        style="
          stroke: #010101;
          stroke-linecap: round;
          stroke-linejoin: round;
          stroke-width: 0.20000000298023224px;
        "
      />
    </g>
    <g
      class="pin"
      :transform="`translate(${targetLoc[0]} ${targetLoc[1]}) scale(1.5)`"
    >
      <path
        fill="#000"
        d="M17.54,3.52A9.9,9.9,0,0,0,12,.21,9.91,9.91,0,0,0,.22,7.65,9.48,9.48,0,0,0,0,10.18c-.16,5.18,3.92,10,7.7,15,.93,1.15,2.23,2.42,2.29,2.57l2.08-2.46C16.82,19.08,23.74,10.05,17.54,3.52Zm-2.86,7.79A4.88,4.88,0,0,1,9,15.16l-.12,0a5,5,0,0,1-3.75-5.9,4.87,4.87,0,0,1,5.78-3.82A5,5,0,0,1,14.68,11.31Z"
      ></path>
      <path
        fill="#fff"
        d="M11.55,2.93A7.37,7.37,0,0,0,2.82,8.77a7.6,7.6,0,0,0,5.67,9l.18,0a7.37,7.37,0,0,0,8.55-5.87A7.59,7.59,0,0,0,11.55,2.93Zm3.13,8.38A4.88,4.88,0,0,1,9,15.16l-.12,0a5,5,0,0,1-3.75-5.9,4.87,4.87,0,0,1,5.78-3.82A5,5,0,0,1,14.68,11.31Z"
      ></path>
      <text :x="`${country === 'Ethiopia' ? -70 : -70}`" y="20" class="small">
        {{ country }}
      </text>
    </g>
    <text
      x="280"
      y="190"
      :class="`small ${isActive('Ethiopia')}`"
      style="font-size: 22px"
    >
      Ethiopia
    </text>
    <text
      x="276"
      y="258"
      :class="`small ${isActive('Rwanda')}`"
      style="font-size: 22px"
    >
      Rwanda
    </text>
    <text
      x="280"
      y="285"
      :class="`small ${isActive('Tanzania')}`"
      style="font-size: 22px"
    >
      Tanzania
    </text>
  </svg>
</template>
<script>
export default {
  name: "Africa",
  data() {
    return {
      targetLoc: [0, 0],
    };
  },
  props: {
    country: String,
  },
  mounted() {
    const target = document.querySelector(".map .active");
    this.targetLoc = [
      target.dataset.loc.split(",")[0],
      target.dataset.loc.split(",")[1],
    ];
    this.log("Map:#901", "Country", this.country, target);
  },
  methods: {
    isActive(query) {
      return query === this.country ? "active" : "";
    },
    quickViewShop(selection) {
      if (selection !== "shop") {
        this.$store.commit("saveFilterInStore", [selection]);
        this.$store.commit("saveActiveFilters", [1, 0, 0, 0]);
      }
      window.location = "#/user/shop";
    },
  },
};
</script>
